<template lang="pug">
  .wrapper.i-wrap.cms-article-wrap
    .section-left.cms-left
      button(v-if="!newArticle" @click="deleteArticle()") Delete
    //- transition(name="slideup")
    .section-mid.cms-mid
      .article-input
        label Main Photo
        .uploadPic
          input( type="file" accept="image/*" @change="onChange")
          VueCropper(v-if="imageUrl" 
            ref="cropper"
            :src="imageUrl"
            alt="Source Image"
            :aspectRatio="12/5"
            :viewMode="1"
            preview=".preview"
            )
        #preview(v-bind:class="{active : imageUrl}")
          img(v-if="poster && !imageUrl" v-bind:src="poster")
        input(v-if="imageUrl" type="text" v-model="posterDescription" placeholder="image description")
      .article-input
        label Title
        input(type="text" v-model="title")
      .article-input
        label Summary
        textarea-autosize(
          placeholder="Your Article Summary..."          
          v-model="summary"
          :min-height="50"
          :max-height="250"
        )
      
      .article-input
        label Content
        vue-editor( v-model="content" :customModules="customModulesForEditor" :editorOptions="editorSettings")
      .link-wrap()
        .link-input()
          label Link Movie, TV Series, or Star
          p Search Type
          input(v-model="linkSearch" v-on:keyup.enter="linkGo()")
          button(@click="linkGo()") Search
          .link-search-list(v-for="(item, index) in linkSearchList")
            router-link(:to="`/${item.model}/${item._id}`" target='_blank')
              .link-search-left
                img.poster(v-bind:src="item.poster")
                div
                  p {{item.nameCH}} 
                  p {{item.nameEN}}
                i.fas.fa-external-link-alt
            .linked-btn
              button(@click="addLink(item, index)") Link 
            //- |{{item}}
      .article-end
        button.publish(v-if="!published" @click="publishArticle(true)") Publish
        button.unpublish(v-else @click="saveArticle(false)") Unpublish
        button.save(@click="saveArticle(published)") Save
      
    .section-right.cms-right
      .article-side
        label Publish Date
        Datepicker(v-model="publishDate")
      .article-side
        label Type of article
        select(v-model="typeOfArticle")
          option( value='新聞' ) 新聞
          option( value='影評' ) 影評
          option( value='專欄' ) 專欄
      .article-side
        label Keywords
        .article-keyword-wrap(v-for="(keyword, index) in keywords")
          i.pointer.fas.fa-minus-square(@click="delKeyword(index)")
          input(v-model="keywords[index]")
        span.pointer.article-add-keyword(@click="addKeyword")
          i.fas.fa-plus
          | &nbsp;Add keyword
        label Linked Items:
        .linked-list(v-for="(item, index) in movies")
          router-link(:to="`/${item.model}/${item._id}`" target='_blank')
            .linked-list-left
              img( v-bind:src="item.poster" style="width:50px; background-color:pink;height:75px;")
              p {{item.nameCH}} 
              p {{item.nameEN}}
              p
              i.fas.fa-external-link-alt
          .linked-btn
            button(@click="removeLinked(item, index)") Remove

        .linked-list(v-for="(item, index) in tvSeries")
          router-link(:to="`/${item.model}/${item._id}`" target='_blank')
            .linked-list-left
              img( v-bind:src="item.poster" style="width:50px; background-color:pink;height:75px;")
              p {{item.nameCH}} 
              p {{item.nameEN}}
              i.fas.fa-external-link-alt
          .linked-btn
            button(@click="removeLinked(item, index)") Remove
        .linked-list(v-for="(item, index) in stars")
          router-link(:to="`/${item.model}/${item._id}`" target='_blank')
            .linked-list-left
              img( v-bind:src="item.poster" style="width:50px; background-color:pink;height:75px;")
              p {{item.nameCH}} 
              p {{item.nameEN}}
              i.fas.fa-external-link-alt
            .linked-btn
              button(@click="removeLinked(item, index)") Remove

</template>

<script>
// @ is an alias to /src
import LeftBar from '@/components/LeftBar'
import RightBar from '@/components/RightBar'
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-vue';
import Datepicker from 'vuejs-datepicker';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';


export default {
  name: 'CmsArticleNewEdit',
  props:{
    id:String,
    articleType:String,
  },
  components: {
    VueEditor,
    Datepicker,
    LeftBar,
    RightBar,
    VueCropper,
  },
  data(){
    return{
      newArticle:true,
      title:'',
      keywords:[],
      poster:null,
      imageUrl:null,
      croppedImage:'',
      content: "",
      publishDate: new Date(),
      published:false,
      typeOfArticle:'新聞',
      posterDescription:null,
      linkModal:false,
      linkSearch:'',
      linkSearchList:[],
      movies:[],
      tvSeries:[],
      stars:[],
      file:null,
      summary:null,
      customModulesForEditor: [{ alias: "imageDrop", module: ImageDrop }, { alias: "imageResize", module: ImageResize }],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
    }
  },
  metaInfo: {
    title: '臭豆腐電影: 電影∣影集∣評分∣評論',
    // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
  },
  methods:{    
    async addLink(item, index){
      var vm = this;
      var duplicate = false;
      function duplicateCheck(modelArray, thingy){
        for(let i = 0; i<modelArray.length; i++){
          if( modelArray[i]._id == thingy._id){ 
            duplicate = true; break
          }
        }
        if(!duplicate){
          modelArray.push(thingy)
          item.nameCH ? vm.flash(`Added ${item.nameCH} - ${item.nameEN}`,'success') : vm.flash(`Added ${item.nameEN}`, 'success')
        }else{
          item.nameCH ? vm.flash(`${item.nameCH} - ${item.nameEN} is Already Added`, 'warning') : vm.flash(`${item.nameEN} is Already Added`, 'warning')
        }
      }
      if(item.model == 'movie')duplicateCheck(this.movies, item)
      if(item.model == 'star')duplicateCheck(this.stars, item)
      if(item.model == 'tvSeries') duplicateCheck(this.tvSeries, item)
    },
    async removeLinked(item, index){
      if(item.model == 'movie') this.movies.splice(index,1)
      if(item.model == 'star') this.stars.splice(index,1)
      if(item.model == 'tvSeries') this.tvSeries.splice(index,1)
    },
    async linkGo(){
      this.linkSearchList = [];
      let response = await this.axios.get(`/api/searchbar?query=${this.linkSearch}`)
      if(response.data.results.length > 0) this.linkSearchList = response.data.results
      else this.flash('no results', 'warning')
      
    },
    async cropImage(){
      // get image data for post processing, e.g. upload or setting image src
      this.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    async deleteArticle(){
      try{
        var dblCheck = confirm(`Are you sure you want to Delete this Article?`);
        if(dblCheck){
          var deleteArticle = await this.axios.delete(`/cms/article/${this.id}`)
          // console.log(deleteArticle)
          if( deleteArticle.data.err) this.flash(`Article Deleted, but ${deleteArticle.data.err}`, 'error')
          this.$router.push(`/user/articles`);

        }
      }catch(err){
        this.flash(err,'error')
      }
    },
    async publishArticle(){
      this.saveArticle(true)
    },
    async saveArticle(publish){
      try{
        var payload = {};
        payload.content = this.content;
        payload.title = this.title;
        payload.poster = this.poster;
        payload.publishDate = this.publishDate;
        payload.status = this.status;
        payload.typeOfArticle = this.typeOfArticle;
        payload.posterDescription = this.posterDescription;
        payload.keywords = this.keywords;
        payload.movies = this.movies;
        payload.tvSeries = this.tvSeries;
        payload.stars = this.stars;
        payload.summary = this.summary;

        if (this.newArticle == true){ 
          payload.published = false;
          var theArticle = await this.axios.post('/cms/article/new/save',payload)
          if(theArticle.data.error) throw('upload failed')
          else {
            this.flash('success','success')
            this.newArticle = false;
            this.uploadImage(theArticle.data._id);
            history.pushState({},null,`/user/article/edit/${theArticle.data._id}`)
            this.id = theArticle.data._id;
          }
        }else{
          if(publish == true) payload.published = true;
          var editedArticle = await this.axios.post(`/cms/article/edit/${this.id}`,payload)
          await this.uploadImage(this.id);
          if(editedArticle.status === 200) {
            this.flash('Article Updated', 'success')
            this.$router.push(`/user/articles`);
          }
        }
      }catch(err){
        this.flash(err,'error')
      }
    },
    async uploadImage(articleId){
      if(this.file){
        // this.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
          this.$refs.cropper.getCroppedCanvas().toBlob(async(blob)=>{
          var formData = new FormData();
          this.croppedImage = new File([blob], "filename.jpg",{type:'image/jpeg'});
          // formData.append('file',this.file)
          formData.append('file',this.croppedImage)
          var uploadImg = await this.axios.post( `/cms/article/uploadImg/${articleId}`,formData,{headers: {'Content-Type': 'multipart/form-data'}})
          if(uploadImg.data) this.flash('Image Uploaded','success')
        },'image/jpeg');
        
      }
    },
    onChange(e) {
      this.imageUrl = null;
      const file = e.target.files[0]
      const reader = new FileReader();
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      this.file = file
      // this.imageUrl = URL.createObjectURL(file)

      reader.onload = (event) => {
        this.imageUrl = event.target.result;
        // rebuild cropperjs with the updated source
        // this.$refs.cropper.replace(event.target.result);
      };
      reader.readAsDataURL(file);

    },
    addKeyword(){
      this.keywords.push("")
    },
    delKeyword(index){
      if(this.keywords.length == 1){
        this.keywords.splice(index,1, '')
      }else{
        this.keywords.splice(index,1)
      }
    }
  },
  async created(){
    if (this.$route.name == 'CmsArticleNew'){ this.newArticle = true;}
    else if (this.$route.name == 'CmsArticleEdit'){ 
      this.newArticle = false;
      let article = await this.axios.get(`/cms/article/edit/${this.id}`);    
      this.title = article.data.title;
      this.keywords = article.data.keywords;
      this.poster = article.data.poster;
      this.imageUrl = article.data.imageUrl;
      this.content = article.data.content;
      this.publishDate = article.data.publishDate
      this.published = article.data.published
      this.typeOfArticle = article.data.typeOfArticle;
      this.posterDescription = article.data.posterDescription;
      this.movies = article.data.movies;
      this.tvSeries = article.data.tvSeries;
      this.stars = article.data.stars;
      this.summary = article.data.summary;
      
    }
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss">
.cms-left{
  // width:30%;
}
.cms-right{
  // width:30%;
}
.cms-mid{
  // width:40%;
}
.link-wrap{
  border:grey solid 1px;
  padding:10px;
  margin:10px 0;
  // background:white;
}
.cms-article-wrap{
  
  .ql-editor{
    background:white;
  }
  #preview{
    max-width:100%;
    max-height: 545px;
    overflow:hidden;
    img{
      width:100%;
    }
  }
  .article-input, .article-side{
    padding:5px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    color: rgba(5, 5, 5, 0.8);
    label{
      margin:5px 0 10px 0;
      font-size:18px;
      font-weight:700;
      border:solid 1px lightgrey;
      padding:3px 10px;
      border-radius:5px;
    }
    input{
      width:100%;
      padding:7px;
      font-size:18px;
      box-shadow: 0 1px 1.6px -0.6px rgb(5 5 5 / 30%);
      // text-align:center;
    }
  }
  .article-end{
    display:flex;
    justify-content: flex-end;
  }
  .article-add-keyword{
    margin:10px;
    align-self: flex-end;
  }
  .article-keyword-wrap{
    display:flex;
    align-items: center;
    margin:10px;
    i{
      font-size:18px;
      margin-right:10px;
    }
  }
}
.linked-btn{
  display:flex;
  justify-content: center;
  align-items: center;
}
.linked-list{
  width:100%;
  display:flex;
  justify-content: space-between;
  margin:10px 0;
  img{
    margin-right:10px;
  }
  .linked-list-left{
    display:flex;
    align-items: center;
  }
}
.link-search-list{
  width:100%;
  display:flex;
  justify-content: space-between;
  margin:10px 0;
  .link-search-left{
    display:flex;
    align-items: center;
  }
  .poster{
    width:50px;
  }
}
</style>
